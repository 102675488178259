
.text-ellipsis {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}

table  td:nth-child(5)
{
  
  max-width: 350px;
}
