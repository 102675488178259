.m10{
    margin:10px;
}
.mt05{
    margin-top: 0.5rem;
}
.mt1{
    margin-top: 1rem;
}
.mt2{
    margin-top: 2rem;
}
.mt3{
    margin-top: 3rem;
}
.mt4{
    margin-top: 4rem;
}
.mt5{
    margin-top: 5rem;
}
.mt6{
    margin-top: 6rem;
}
.mt10{
    margin-top: 10rem;
}
