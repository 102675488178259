body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.mycontainer
{
    background-color: #3d8a3d;
    color: white;
  
    padding: 5px;
}
.container
{
  width: 100%;
  border: thick;
  margin-top: 1rem;
}
.b-1
{
  border: 1px solid grey;
  margin-top: 1rem;
}
.tbl-style
{
  width: 100%;
  border-collapse: collapse;
}


.tbl-style thead
{
  background-color: #357735;
  color:white;
  
}
.tbl-style td
{
  border: 1px solid rgb(146, 146, 146);
  font-size: 12px;
  padding: 2px;
  
}
.scrolldiv
{
  overflow-x: auto;
}


.mytxtbox
{
  font-size: 2px;
}



.fcol
{
  min-width: 350px;
  
}

.fco2
{
  min-width: 40px;
  padding-left: 5px;
  padding-right: 5px;
  text-align: center;
}


.txtbox
{
  width: 30px;
  padding: 5px;
  border: solid 1px #adadad;
  -webkit-box-shadow: 0px 0px 2px 0px rgba(240,240,240,1);
-moz-box-shadow: 0px 0px 2px 0px rgba(240,240,240,1);
box-shadow: 0px 0px 2px 0px rgba(240,240,240,1);
border-radius: 5px;
text-align: center;
}

.txtbox:focus
{
  border: solid 1px green;
  outline: none;
}

.chkbox
{
  width: 18px;
  height: 18px;
  margin-right: 6px;
  position: relative;
  top: 5px;
}

.chkbox2
{
  width: 18px;
  height: 18px;
  margin-left: 5px;
 
}

.txtcenter
{
  text-align: center;
}

.mt-5
{
  margin-top: 2rem;
}
.mt-4
{
  margin-top: 1.2rem;
}
.ddldropdown
{
  padding: 5px;
  width: 100%;
  border: solid 1px #adadad;
  border-radius: 5px;
}

      

.greenhead thead th
{
  background-color: #357735;
  font-weight: 400 !important;
  color: white !important;
}

.blockclass
{
  display: flex;
  width: 100%;
  height: 60px;
  background-color: rgb(233, 233, 59);
}

.mantine-1xelxnd {
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  width: 92vw;
  box-sizing: border-box;
  min-height: 100vh;
  padding-top: calc(var(--mantine-header-height, 0px) + 1rem);
  padding-bottom: calc(var(--mantine-footer-height, 0px) + 1rem);
  padding-left: calc(var(--mantine-navbar-width, 0px) + 1rem);
  padding-right: calc(var(--mantine-aside-width, 0px) + 1rem);
}

.red-text {
  color: red;
}

.black-text {
  color: black;
}

/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #c2c2c2 #f2f2f2;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 18px;
}

*::-webkit-scrollbar-track {
  background: #f2f2f2;
}

*::-webkit-scrollbar-thumb {
  background-color: #c2c2c2;
  border-radius: 10px;
  border: 3px solid #ffffff;
}

.mantine-1avyp1d {
  margin-top: 16px !important;
}


.text-ellipsiss {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}

.green
{
  background-color: #3d8a3d !important;
  color: white !important;
}

.green th
{
  background-color: #3d8a3d !important;
  color: white !important;
}

.tableheader
{
  background-color: #3d8a3d !important;
  color: white !important;
}

.tableheader th
{
  background-color: #3d8a3d !important;
  color: white !important;
  font-weight: 400 !important;
}